import request from '@/../utils/request'
const api_name = '/iotechserver/deviceInfo'

export default {
    // 查询所有设备
    getAllDeviceTypeAboutMap() {
        return request({
            url: `${api_name}/getAllDeviceTypeAboutMap/`,
            method: 'get'
        })
    },
    // 查询地图指定设备类型设备
    getDeviceTypeAboutMap(typeId) {
        return request({
            url: `${api_name}/getDeviceTypeAboutMap/${typeId}`,
            method: 'get'
        })
    },
    // 查询地图单个设备
    getOneDeviceTypeAboutMap(typeId) {
        return request({
            url: `${api_name}/getOneDeviceTypeAboutMap/${typeId}`,
            method: 'get'
        })
    },

}